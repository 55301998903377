@tailwind base;
@tailwind components;
@tailwind utilities;

.app-max-w-content {
    max-width: min(90vw, var(--target-content-width, 1200px))
}

.percentage {
    position: relative;
    overflow: hidden;
}

.percentage::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(var(--percentage-fill, 0%));
    background-color: var(--percentage-background-color, #000000);
    border-top: 1px solid black;
}

.percentage.percentage-full::after {
    height: 100%;
    border-top: 0px;
}

.percentage.percentage-hidden::after {
    height: 0%;
    border-top: 0px;
}